<!-- 政策 -->
<template>
  <div class="policy-frame">
    <el-backtop :visibility-height="400" @click="backTotop"></el-backtop>
    <div class="body">
      <top showTitle="政策"></top>
      <div class="content" v-show="!nowData.id">
        <div class="left">
          <div class="navs">
            <div
              :class="{ nav: true, active: queryParams.typeFlag == 0 }"
              @click="changeType(0)"
            >
              投资政策
            </div>
            <div
              :class="{ nav: true, active: queryParams.typeFlag == 1 }"
              @click="changeType(1)"
            >
              解读政策
            </div>
            <div class="white"></div>
          </div>
        </div>
        <div class="right">
          <div class="list">
            <div
              class="item"
              v-for="item in tableData"
              :key="item.id"
              @click="searchDetail(item)"
            >
              <div class="title">{{ item.policyName }}</div>
              <div class="time">
                {{ handlePublish(item.publishTime || item.createdTime) }}
              </div>
            </div>
            <el-empty
              v-show="tableData.length == 0"
              description="当前分栏暂无相关政策"
            ></el-empty>
          </div>
          <el-pagination
            background
            small
            @current-change="pageIdxChange"
            :current-page.sync="queryParams.pageIndex"
            layout="prev, pager, next"
            :total="totalData"
            :page-size="queryParams.pageSize"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 政策详情 -->
      <div class="detail" v-show="nowData.id">
        <el-page-header @back="goBack" content="详情"></el-page-header>

        <div class="new">
          <div class="title">{{ nowData.policyName }}</div>
          <div class="time">发布时间：{{ handleTime }}</div>
          <div class="html" v-html="nowData.policyContext"></div>
        </div>
      </div>
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import Bottom from "../../components/bottom.vue";
import top from "../../components/top.vue";
export default {
  components: { top, Bottom },
  data() {
    return {
      queryParams: {
        pageIndex: 1,
        pageSize: 10,
        typeFlag: 0,
      },
      tableData: [],
      totalData: 0,
      bannerData: [], // 最上面几个的，用于轮播的数据
      nowData: {},
    };
  },
  created() {
    this.getList();
    const { id } = this.$route.query;
    if (id) {
      this.$api.getPolicyById({ id }).then((res) => {
        this.nowData = res.data;
      });
    }
  },
  computed: {
    handleTime() {
      let { nowData } = this;
      if (nowData.id) {
        const time =
          nowData.publishTime || nowData.updateTime || nowData.createdTime;
        return time.split(" ")[0];
      } else {
        return "";
      }
    },
    handlePublish() {
      return (time) => {
        return time.split(" ")[0];
      };
    },
  },
  mounted() {},
  methods: {
    async getList() {
      let res = await this.$api.getPolicy(this.queryParams);
      this.tableData = res.data.list;
      this.bannerData = res.data.list.filter((ele, idx) => idx < 5);
      this.totalData = res.data.total;
    },
    pageIdxChange(val) {
      this.queryParams.pageIndex = val;
    },
    changeType(type) {
      if (type != this.queryParams.typeFlag) {
        this.queryParams.typeFlag = type;
        this.getList();
      }
    },
    searchDetail(item) {
      if (item.policyModule == 1) {
        // 链接跳转
        window.open(item.policyContext);
      } else {
        // 内容跳转
        this.nowData = item;
      }
    },
    goBack() {
      this.nowData = {};
    },
    backTotop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.policy-frame {
  background-image: url("../../assets/policy_bg.png");
  background-size: 100% 100%;

  ::v-deep .html img {
    max-width: 100%;
    height: auto;
  }
}
.content {
  width: 100%;
  min-width: 1100px;
  background-color: rgb(250 251 253 / 70%);
  position: relative;
  box-shadow: 0 2px 5px rgba($color: #aaa, $alpha: 0.3);
  padding: 34px;
  box-sizing: border-box;
  display: flex;

  .navs {
    width: 200px;
    height: 100%;
    text-align: center;
    font-size: 17px;
    display: flex;
    flex-direction: column;

    .nav {
      cursor: pointer;
      padding: 10px 0;
      margin-bottom: 5px;
      position: relative;
      background-color: #fff;

      &:hover::before {
        width: 2px;
        transition: width 0.4s;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        background-color: $theme;
      }
    }
    .active {
      color: $theme;

      &::before {
        width: 2px;
      }
    }
    .white {
      flex-grow: 1;
      background-color: #fff;
    }
  }
  .right {
    margin-left: 30px;
    flex-grow: 1;
  }

  .list {
    width: 100%;
    height: calc(100% - 40px);
    overflow: auto;

    .item {
      width: 100%;
      margin-bottom: 10px;
      padding: 10px 0 10px 20px;
      box-sizing: border-box;
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      &:hover {
        color: $theme;

        &::after {
          height: 2px;
          background-color: $theme;
        }
      }

      &::before {
        content: "";
        display: block;
        width: 5px;
        height: 5px;
        background-color: #999;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: #99999933;
        transition: height 0.2s;
      }

      .title {
        // max-width: 700px;
        flex-grow: 1;
        font-size: 17px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .time {
        font-size: 14px;
        color: #666;
        flex-shrink: 0;
        margin-left: 10px;
      }
    }
  }
}

.detail {
  width: 100%;
  min-height: calc(100vh - 315px);
  padding: 34px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba($color: #aaa, $alpha: 0.3);

  .new {
    text-align: center;

    .title {
      font-size: 25px;
      font-weight: bold;
      margin: 30px 0 10px;
    }
    .time {
      color: #666;
      margin-bottom: 10px;
    }
    .html {
      text-align: left;
    }
  }
}

@media (max-width: 1700px) {
  .content .left .list {
    width: 600px;
  }
}
@media (max-width: 1500px) {
  .content {
    .left .list {
      width: 450px;

      .item {
        font-size: 14px;
      }
    }
    .right {
      width: 700px;
    }
  }
}
</style>